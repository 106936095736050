.fused-box .form-item-wrapper, .fused-box .form-item-child-wrapper  {
  margin-left:0;
  margin-right:0;  
  flex:1;
}

.fused-box label {
  height:0px;
  display:block;
  margin-bottom: 0px;
}

.fused-box .form-item-child-wrapper {
  border-radius: 0 0 0 0;
}
.fused-box .form-item-child-wrapper:first-child {
  border-radius: 0 0 0 0;
}

.fused-box .form-item-wrapper:first-child {
  flex: 3;
}